.empty {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list {
  padding: 20px;
}
.item {
  border-radius: 5px;
  background-color: #f1f3f5;
  cursor: pointer;
}